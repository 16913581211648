<template>
    <!-- 表单管理 -->

    <div class="formment-box">
        <div class="box">
            <div class="title">
                <div class="t-label">
                    <label>角色管理</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加角色" : "修改角色" }}</label>
                </div>
            </div>
            <div class="setbox">
                <div class="inputbox">
                    <el-form ref="form" :model="form" label-width="150px" :rules="rules">
                        <el-form-item label="角色名称" prop="roleName">
                            <el-input v-model="form.roleName" placeholder="请输入角色名称"></el-input>
                        </el-form-item>
                        <el-form-item label="角色权限" prop="roleAuth">
                            <div style="display: flex">
                                <div>
                                    <el-switch v-model="form.roleAuth" :active-value="2" :inactive-value="1"></el-switch>
                                    <span style="margin-left: 10px">查看</span>
                                </div>
                                <div style="margin-left: 20px">
                                    <el-switch v-model="form.roleAuth" :active-value="1" :inactive-value="2"></el-switch>
                                    <span style="margin-left: 10px">管理</span>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btn-box">
                    <el-button @click="$router.push('/systemset/rolement')">取消</el-button>
                    <el-button type="primary" @click="addInfo" v-if="type == 1">添加</el-button>
                    <el-button type="primary" @click="editInfo" v-else-if="type == 2">编辑</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from '@/utils/rules'
export default {
    data() {
        return {
            rules:rules,
            type: "1",
            platform_id: "",
            form: {
                id:'',
                roleName: "",
                roleAuth: 2,
            },
        };
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.$route.query.data) {
            let {id, name,auth } = JSON.parse(this.$route.query.data);
            this.form.id = id
            this.form.roleName = name
            this.form.roleAuth = auth
        }
    },
    methods: {
        addInfo() {
            const that = this;
            let { roleName, roleAuth } = that.form;
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.role.addRole,
                        method: "post",
                        data: {
                            name: roleName,
                            auth: roleAuth,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/systemset/rolement");
                    });
                }
            });
        },
        editInfo() {
            const that = this;
            let { id,roleName, roleAuth } = that.form;
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.role.editRole,
                        method: "post",
                        data: {
                            id:id,
                            name: roleName,
                            auth: roleAuth,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/systemset/rolement");
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    padding: 33px 100px;
    background: #fff;
    margin-top: 30px;
}
.editor {
    margin: 20px;
    /deep/.ql-editor {
        min-height: 200px !important;
    }
}
.formment-box {
    height: 100%;
    padding: 66px;
    //   min-height: 600px;
    .setbox {
        margin-top: 20px;
        color: #333333;

        .title {
            background: #edefff;
            height: 35px;
            line-height: 35px;
            padding-left: 15px;
        }
        /deep/.el-input__inner {
            width: 420px;
        }
        /deep/.input-id {
            margin-left: 20px;
            width: 100px;
        }
        /deep/.input-id .el-input__inner {
            width: 100px;
        }
        /deep/.el-form-item__content {
            // display: flex;
            // align-items: center;
        }
        .inputbox {
            //   margin-left: 15%;

            margin-top: 24px;
        }
    }

    .box {
        border-radius: 16px;
        width: 100%;
        min-height: 100%;
        background: #fff;
        padding: 33px;
        .top-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /deep/.el-breadcrumb__item {
                padding-bottom: 10px;

                font-size: 20px !important;
                //
            }
            /deep/.active {
                color: #4458fe !important;
                border-bottom: #4458fe 2px solid;
                .el-breadcrumb__inner {
                    color: #4458fe !important;
                }
            }
        }
    }
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}
</style>
